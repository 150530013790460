<template>
    <v-row>

        <v-col md="12" cols="12">
            <v-card flat class="pa-3 mt-2" height="auto">
                <v-form class="multi-col-validation">
                    <v-card-text class="">
                        <v-row>
                            <v-col md="12" cols="12" class="mt-3">
                                <p class="text-h6">Consultas - Total: {{ totalConsulta }}</p>
                            </v-col>

                            <v-col md="1" cols="12" class="text-right">
                                <v-btn icon color="primary" dark @click="limparPesquisaConsulta()">
                                    <v-icon>
                                        {{ icons.mdiAutorenew }}
                                    </v-icon>
                                </v-btn>
                                <v-btn icon color="primary" dark @click="pesquisarConsulta()">
                                    <v-icon>
                                        {{ icons.mdiMagnify }}
                                    </v-icon>
                                </v-btn>
                            </v-col>
                            <v-col md="5" cols="12">
                                <v-text-field v-model="dataDeConsulta" label="Data de" v-mask="'##/##/####'" dense
                                    outlined>
                                </v-text-field>
                            </v-col>
                            <v-col md="5" cols="12">
                                <v-text-field v-model="dataAteConsulta" label="Data até" v-mask="'##/##/####'" dense
                                    outlined>
                                </v-text-field>
                            </v-col>


                            <v-col md="12" cols="12">
                                <v-text-field v-model="searchConsulta" append-icon="mdi-magnify" label="Pesquisar"
                                    single-line hide-details>
                                </v-text-field>
                            </v-col>


                            <v-col cols="12" md="12">
                                <v-data-table :headers="headersConsulta" :items="consultas" :search="searchConsulta"
                                    height="250px" sort-by="nome" :footer-props="{
                                        showFirstLastPage: true,
                                        'items-per-page-text': 'Dados por página'
                                    }">


                                    <template v-slot:no-data>
                                        Nenhuma conta registrada
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-card-text>

                </v-form>
                <v-snackbar v-model="snackbar" :color="color" class="text-center">
                    {{ text }}
                </v-snackbar>
            </v-card>
        </v-col>

        <v-col md="6" cols="12">
            <v-card flat class="pa-3 mt-2" height="auto">
                <v-form class="multi-col-validation">
                    <v-card-text class="">
                        <v-row>
                            <v-col md="10" cols="12" class="mt-3">
                                <p class="text-h6">Contas a receber - Total: {{ totalReceber }}</p>
                            </v-col>

                            <v-col md="2" cols="12" class="mt-3">
                                <v-btn color="primary" dark @click="dialog = true" block>
                                    <v-icon small>
                                        {{ icons.mdiContentSave }}
                                    </v-icon>
                                </v-btn>
                            </v-col>
                            <v-col md="3" cols="12" class="text-right">
                                <v-btn icon color="primary" dark @click="limparPesquisaReceber()">
                                    <v-icon>
                                        {{ icons.mdiAutorenew }}
                                    </v-icon>
                                </v-btn>
                                <v-btn icon color="primary" dark @click="pesquisarReceber()">
                                    <v-icon>
                                        {{ icons.mdiMagnify }}
                                    </v-icon>
                                </v-btn>
                            </v-col>
                            <v-col md="4" cols="12">
                                <v-text-field v-model="dataDeReceber" label="Data de" v-mask="'##/##/####'" dense
                                    outlined>
                                </v-text-field>
                            </v-col>
                            <v-col md="4" cols="12">
                                <v-text-field v-model="dataAteReceber" label="Data até" v-mask="'##/##/####'" dense
                                    outlined>
                                </v-text-field>
                            </v-col>

                            <v-col md="12" cols="12">
                                <v-text-field v-model="search" append-icon="mdi-magnify" label="Pesquisar" single-line
                                    hide-details>
                                </v-text-field>
                            </v-col>

                            <v-col cols="12" md="12" style="margin-top: 0px;">
                                <v-data-table :headers="headers" :items="financeiroReceber" :search="search"
                                    sort-by="nome" :footer-props="{
                                        showFirstLastPage: true,
                                        'items-per-page-text': 'Dados por página'
                                    }">

                                    <template v-slot:top>
                                        <v-dialog v-model="dialog" persistent max-width="600px"
                                            transition="dialog-bottom-transition">

                                            <v-card>
                                                <v-toolbar>
                                                    <v-btn icon @click="fecharModal()">
                                                        <v-icon>{{ icons.mdiClose }}</v-icon>
                                                    </v-btn>
                                                    <v-toolbar-title>Cadastro contas a receber</v-toolbar-title>
                                                    <v-spacer></v-spacer>
                                                    <v-btn dark text @click="alterarFinanceiro()" class="primary">
                                                        <v-icon>{{ icons.mdiContentSave }}</v-icon>
                                                    </v-btn>
                                                </v-toolbar>
                                                <v-list three-line subheader>
                                                    <v-subheader>Dados obrigatórios</v-subheader>
                                                    <v-card-text ref="form">
                                                        <v-form class="multi-col-validation mt-6">
                                                            <v-row>

                                                                <v-col md="12" cols="12">
                                                                    <v-text-field v-model="financeiroM.descricao"
                                                                        label="Descrição *" dense outlined
                                                                        ref="financeiroM">
                                                                    </v-text-field>
                                                                </v-col>

                                                                <v-col md="6" cols="12">
                                                                    <v-text-field v-model="financeiroM.valor"
                                                                        label="Valor *" dense outlined v-money="money"
                                                                        ref="valor">
                                                                    </v-text-field>
                                                                </v-col>

                                                                <v-col md="6" cols="12">
                                                                    <v-menu v-model="menu1"
                                                                        :close-on-content-click="false"
                                                                        transition="scale-transition" offset-y
                                                                        max-width="290px" min-width="auto">
                                                                        <template v-slot:activator="{ on, attrs }">
                                                                            <v-text-field
                                                                                v-model="computedDateFormatted" dense
                                                                                outlined label="Data" readonly
                                                                                v-bind="attrs" v-on="on">
                                                                            </v-text-field>
                                                                        </template>
                                                                        <v-date-picker v-model="date" no-title
                                                                            @input="menu2 = false"></v-date-picker>
                                                                    </v-menu>
                                                                </v-col>
                                                            </v-row>
                                                        </v-form>
                                                    </v-card-text>

                                                </v-list>
                                            </v-card>
                                        </v-dialog>
                                        <v-dialog v-model="dialogDelete" max-width="500px">
                                            <v-card class="text-center">
                                                <v-card-text class="text-h5 text-center">Deseja deletar essa conta ?
                                                </v-card-text>
                                                <v-card-actions>
                                                    <v-spacer></v-spacer>
                                                    <v-btn color="warning" outlined @click="closeDelete">Cancelar
                                                    </v-btn>
                                                    <v-btn color="success" outlined @click="deleteItemConfirm">OK
                                                    </v-btn>
                                                    <v-spacer></v-spacer>
                                                </v-card-actions>
                                            </v-card>
                                        </v-dialog>
                                    </template>
                                    <template v-slot:[`item.actions`]="{ item }">
                                        <v-icon small @click="deleteItem(item)">
                                            {{ icons.mdiDelete }}
                                        </v-icon>
                                    </template>
                                    <template v-slot:no-data>
                                        Nenhuma conta registrada
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-card-text>

                </v-form>
                <v-snackbar v-model="snackbar" :color="color" class="text-center">
                    {{ text }}
                </v-snackbar>
            </v-card>
        </v-col>

        <v-col md="6" cols="12">
            <v-card flat class="pa-3 mt-2" height="auto">
                <v-form class="multi-col-validation">
                    <v-card-text class="">
                        <v-row>
                            <v-col md="10" cols="12" class="mt-3">
                                <p class="text-h6">Contas recebidas - Total: {{ totalRecebido }}</p>
                            </v-col>

                            <v-col md="2" cols="12" class="mt-3">
                                <v-btn color="primary" dark @click="dialogRecebido = true" block>
                                    <v-icon small>
                                        {{ icons.mdiContentSave }}
                                    </v-icon>
                                </v-btn>
                            </v-col>
                            <v-col md="3" cols="12" class="text-right">
                                <v-btn icon color="primary" dark @click="limparPesquisaRecebido()">
                                    <v-icon>
                                        {{ icons.mdiAutorenew }}
                                    </v-icon>
                                </v-btn>
                                <v-btn icon color="primary" dark @click="pesquisarRecebido()">
                                    <v-icon>
                                        {{ icons.mdiMagnify }}
                                    </v-icon>
                                </v-btn>
                            </v-col>
                            <v-col md="4" cols="12">
                                <v-text-field v-model="dataDeRecebido" label="Data de" v-mask="'##/##/####'" dense
                                    outlined>
                                </v-text-field>
                            </v-col>
                            <v-col md="4" cols="12">
                                <v-text-field v-model="dataAteRecebido" label="Data até" v-mask="'##/##/####'" dense
                                    outlined>
                                </v-text-field>
                            </v-col>

                            <v-col md="12" cols="12">
                                <v-text-field v-model="searchRecebido" append-icon="mdi-magnify" label="Pesquisar"
                                    single-line hide-details>
                                </v-text-field>
                            </v-col>

                            <v-col cols="12" md="12">
                                <v-data-table :headers="headers" :items="financeiroRecebido" :search="searchRecebido"
                                    sort-by="nome" :footer-props="{
                                        showFirstLastPage: true,
                                        'items-per-page-text': 'Dados por página'
                                    }">

                                    <template v-slot:top>
                                        <v-dialog v-model="dialogRecebido" persistent max-width="600px"
                                            transition="dialog-bottom-transition">
                                            <v-card>
                                                <v-toolbar>
                                                    <v-btn icon @click="fecharModalRecebido()">
                                                        <v-icon>{{ icons.mdiClose }}</v-icon>
                                                    </v-btn>
                                                    <v-toolbar-title>Cadastro contas recebidas</v-toolbar-title>
                                                    <v-spacer></v-spacer>
                                                    <v-btn dark text @click="alterarFinanceiroRecebido()"
                                                        class="primary">
                                                        <v-icon>{{ icons.mdiContentSave }}</v-icon>
                                                    </v-btn>
                                                </v-toolbar>
                                                <v-list three-line subheader>
                                                    <v-subheader>Dados obrigatórios</v-subheader>
                                                    <v-card-text ref="form">
                                                        <v-form class="multi-col-validation mt-6">
                                                            <v-row>

                                                                <v-col md="12" cols="12">
                                                                    <v-text-field
                                                                        v-model="financeiroMRecebido.descricao"
                                                                        label="Descrição *" dense outlined
                                                                        ref="financeiroMRecebido">
                                                                    </v-text-field>
                                                                </v-col>

                                                                <v-col md="6" cols="12">
                                                                    <v-text-field v-model="financeiroMRecebido.valor"
                                                                        label="Valor *" dense outlined v-money="money"
                                                                        ref="valor">
                                                                    </v-text-field>
                                                                </v-col>

                                                                <v-col md="6" cols="12">
                                                                    <v-menu v-model="menu2"
                                                                        :close-on-content-click="false"
                                                                        transition="scale-transition" offset-y
                                                                        max-width="290px" min-width="auto">
                                                                        <template v-slot:activator="{ on, attrs }">
                                                                            <v-text-field
                                                                                v-model="computedDateFormattedRecebido"
                                                                                dense outlined label="Data" readonly
                                                                                v-bind="attrs" v-on="on">
                                                                            </v-text-field>
                                                                        </template>
                                                                        <v-date-picker v-model="dateRecebido" no-title
                                                                            @input="menu2 = false"></v-date-picker>
                                                                    </v-menu>
                                                                </v-col>
                                                            </v-row>
                                                        </v-form>
                                                    </v-card-text>

                                                </v-list>
                                            </v-card>
                                        </v-dialog>
                                        <v-dialog v-model="dialogDeleteRecebido" max-width="500px">
                                            <v-card class="text-center">
                                                <v-card-text class="text-h5 text-center">Deseja deletar essa conta ?
                                                </v-card-text>
                                                <v-card-actions>
                                                    <v-spacer></v-spacer>
                                                    <v-btn color="warning" outlined
                                                        @click="dialogDeleteRecebido = false">Cancelar
                                                    </v-btn>
                                                    <v-btn color="success" outlined @click="deleteItemConfirmRecebido">
                                                        OK
                                                    </v-btn>
                                                    <v-spacer></v-spacer>
                                                </v-card-actions>
                                            </v-card>
                                        </v-dialog>
                                    </template>
                                    <template v-slot:[`item.actions`]="{ item }">
                                        <v-icon small @click="deleteItemRecebido(item)">
                                            {{ icons.mdiDelete }}
                                        </v-icon>
                                    </template>
                                    <template v-slot:no-data>
                                        Nenhuma conta registrada
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-card-text>

                </v-form>
                <v-snackbar v-model="snackbar" :color="color" class="text-center">
                    {{ text }}
                </v-snackbar>
            </v-card>
        </v-col>


    </v-row>

</template>

<script>
import Vue from "vue";
import { ref } from '@vue/composition-api'
import { mdiClose, mdiContentSave, mdiDelete, mdiPencil, mdiMagnify, mdiAutorenew } from '@mdi/js'

import { VMoney } from 'v-money'

import { mask } from 'vue-the-mask'

export default {

    directives: { money: VMoney, mask }
    ,

    setup() {

        return {
            search: "",
            searchRecebido: "",
            searchConsulta: "",
            totalReceber: "",
            totalRecebido: "",
            totalConsulta: "",
            financeiroM: { id: null, descricao: "", valor: "", data: "", idEmpresa: localStorage.getItem("idEmpresa"), token: localStorage.getItem("token") },
            financeiroMRecebido: { id: null, descricao: "", valor: "", data: "", idEmpresa: localStorage.getItem("idEmpresa"), token: localStorage.getItem("token") },
            dialog: false,
            dialogRecebido: false,
            dialogDeleteRecebido: false,
            dialogDelete: false,
            money: {
                decimal: ',',
                thousands: '.',
                precision: 2,
                masked: false /* doesn't work with directive */
            },

            icons: {
                mdiClose,
                mdiDelete,
                mdiContentSave,
                mdiPencil,
                mdiMagnify,
                mdiAutorenew
            },
            snackbar: false,
            text: '',
            color: 'success',

            headers: [
                {
                    text: 'Descrição',
                    align: 'start',
                    value: 'descricao',
                    sortable: false
                },
                { text: 'Dalor', value: 'valor', sortable: false },
                { text: 'Data', value: 'data', sortable: false },
                { text: 'Ações', value: 'actions', sortable: false },
            ],

            headersConsulta: [
                {
                    text: 'Consulta',
                    align: 'start',
                    value: 'consulta',
                    sortable: false
                },
                { text: 'Dia da consulta', value: 'dataModificada', sortable: false },
                { text: 'Valor', value: 'valorModificado', sortable: false },
            ],

            financeiroReceber: [],
            financeiroRecebido: [],
            menu1: false,
            menu2: false,
            date: "",
            dateRecebido: "",
            consultas: [],
            contasReceberConsultas: false,

            dataDeConsulta: "",
            dataAteConsulta: "",
            dataDeReceber: "",
            dataAteReceber: "",
            dataDeRecebido: "",
            dataAteRecebido: ""
        }
    },

    methods: {

        pegarDados: function () {
            this.$http.get("financeiro?idEmpresa=" + localStorage.getItem("idEmpresa") + "&token=" + localStorage.getItem("token") + "&tipo=" + 1)
                .then((res) => {
                    if (res.data.status != undefined && res.data.status == 200) {
                        this.financeiroReceber = res.data.dados
                        var totalReceber = 0;
                        for (var i = 0; i < this.financeiroReceber.length; i++) {
                            totalReceber += parseFloat(this.financeiroReceber[i].valor)
                            this.financeiroReceber[i].valor = parseFloat(this.financeiroReceber[i].valor).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
                            this.financeiroReceber[i].data = this.dataAtualFormatada(new Date(this.financeiroReceber[i].data))
                        }

                        this.totalReceber = totalReceber.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })

                        this.$forceUpdate();
                    }
                })
                .catch((error) => {
                    console.log(error);
                });

            this.$http.get("financeiro?idEmpresa=" + localStorage.getItem("idEmpresa") + "&token=" + localStorage.getItem("token") + "&tipo=" + 2)
                .then((res) => {
                    if (res.data.status != undefined && res.data.status == 200) {
                        this.financeiroRecebido = res.data.dados
                        var totalRecebido = 0;
                        for (var i = 0; i < this.financeiroRecebido.length; i++) {
                            totalRecebido += parseFloat(this.financeiroRecebido[i].valor)
                            this.financeiroRecebido[i].valor = parseFloat(this.financeiroRecebido[i].valor).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
                            this.financeiroRecebido[i].data = this.dataAtualFormatada(new Date(this.financeiroRecebido[i].data))
                        }

                        this.totalRecebido = totalRecebido.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })

                        this.$forceUpdate();
                    }
                })
                .catch((error) => {
                    console.log(error);
                });

            this.$http.get("consultas?idEmpresa=" + localStorage.getItem("idEmpresa") + "&token=" + localStorage.getItem("token"))
                .then((res) => {
                    if (res.data.status != undefined && res.data.status == 200) {
                        this.consultas = res.data.dados
                        var totalConsulta = 0;
                        for (var i = 0; i < this.consultas.length; i++) {
                            totalConsulta += parseFloat(this.consultas[i].valor)
                            this.consultas[i].dataModificada = this.consultas[i].data.split("T")
                            this.consultas[i].dataModificada = this.formatDate(this.consultas[i].dataModificada[0])

                            this.consultas[i].valorModificado = parseFloat(this.consultas[i].valor).toLocaleString("pt-BR", { style: "currency", currency: "BRL" });
                            this.consultas[i].confirmacaoModificado = "Não informado";
                            if (this.consultas[i].confirmacao == 2) {
                                this.consultas[i].confirmacaoModificado = "Confirmado";
                            } else if (this.consultas[i].confirmacao == 3) {
                                this.consultas[i].confirmacaoModificado = "Não Confirmado";
                            }
                        }

                        this.totalConsulta = totalConsulta.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })

                        this.$forceUpdate();
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        fecharModal: function () {
            this.formHasErrors = false
            this.financeiroM = { id: null, descricao: "", valor: "", data: "", idEmpresa: localStorage.getItem("idEmpresa"), token: localStorage.getItem("token") };
            this.dialog = !this.dialog
        },

        fecharModalRecebido: function () {
            this.formHasErrors = false
            this.financeiroMRecebido = { id: null, descricao: "", valor: "", data: "", idEmpresa: localStorage.getItem("idEmpresa"), token: localStorage.getItem("token") };
            this.dialogRecebido = !this.dialogRecebido
        },

        alterarFinanceiro: function () {
            this.financeiroM.tipo = 1
            this.financeiroM.data = this.date;
            this.financeiroM.valor = this.financeiroM.valor.replace(".", "").replace(",", ".")
            this.financeiroM.idEmpresa = localStorage.getItem("idEmpresa")
            this.financeiroM.token = localStorage.getItem("token")

            if (this.financeiroM.descricao != "" && this.financeiroM.valor != "" && this.financeiroM.data != "") {
                this.$http.post("cadastroFinanceiro", this.financeiroM)
                    .then((res) => {
                        if (res.data.status != undefined && res.data.status == 200) {
                            this.text = "Conta cadastrada"
                            this.color = "success"
                            this.snackbar = true
                            this.dialog = !this.dialog
                            this.pegarDados()

                        } else if (res.data.status != undefined && res.data.status == 500) {
                            this.text = res.data.msg
                            this.color = "error"
                            this.snackbar = true
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });

            } else {
                this.text = "Favor preencher todos campos obrigatórios"
                this.color = "warning"
                this.snackbar = true
            }
        },

        alterarFinanceiroRecebido: function () {
            this.financeiroMRecebido.tipo = 2;
            this.financeiroMRecebido.data = this.dateRecebido;
            this.financeiroMRecebido.valor = this.financeiroMRecebido.valor.replace(".", "").replace(",", ".")
            this.financeiroMRecebido.idEmpresa = localStorage.getItem("idEmpresa")
            this.financeiroMRecebido.token = localStorage.getItem("token")

            if (this.financeiroMRecebido.descricao != "" && this.financeiroMRecebido.valor != "" && this.financeiroMRecebido.data != "") {
                this.$http.post("cadastroFinanceiro", this.financeiroMRecebido)
                    .then((res) => {
                        if (res.data.status != undefined && res.data.status == 200) {
                            this.text = "Conta cadastrada"
                            this.color = "success"
                            this.snackbar = true
                            this.dialogRecebido = !this.dialogRecebido
                            this.pegarDados()

                        } else if (res.data.status != undefined && res.data.status == 500) {
                            this.text = res.data.msg
                            this.color = "error"
                            this.snackbar = true
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });

            } else {
                this.text = "Favor preencher todos campos obrigatórios"
                this.color = "warning"
                this.snackbar = true
            }
        },

        pesquisarConsulta() {
            if (this.dataDeConsulta == "" || this.dataAteConsulta == "") {
                this.text = "Favor preencher os campos"
                this.color = "warning"
                this.snackbar = true
                return
            }

            var [day, month, year] = this.dataDeConsulta.split('/')
            day = (parseInt(day)).toString().padStart(2, '0')

            var dataDe = new Date(year + "-" + month + "-" + day)

            var [day, month, year] = this.dataAteConsulta.split('/')
            day = (parseInt(day)).toString().padStart(2, '0')
            var dataAte = new Date(year + "-" + month + "-" + day)

            if (!isNaN(dataDe) && !isNaN(dataAte)) {
                this.$http.get("consultas?idEmpresa=" +
                    localStorage.getItem("idEmpresa") +
                    "&token=" + localStorage.getItem("token") +
                    "&dataDe=" + dataDe +
                    "&dataAte=" + dataAte)
                    .then((res) => {
                        if (res.data.status != undefined && res.data.status == 200) {
                            this.consultas = res.data.dados
                            var totalConsulta = 0;
                            for (var i = 0; i < this.consultas.length; i++) {
                                totalConsulta += parseFloat(this.consultas[i].valor)
                                this.consultas[i].dataModificada = this.consultas[i].data.split("T")
                                this.consultas[i].dataModificada = this.formatDate(this.consultas[i].dataModificada[0])

                                this.consultas[i].valorModificado = parseFloat(this.consultas[i].valor).toLocaleString("pt-BR", { style: "currency", currency: "BRL" });
                                this.consultas[i].confirmacaoModificado = "Não informado";
                                if (this.consultas[i].confirmacao == 2) {
                                    this.consultas[i].confirmacaoModificado = "Confirmado";
                                } else if (this.consultas[i].confirmacao == 3) {
                                    this.consultas[i].confirmacaoModificado = "Não Confirmado";
                                }
                            }

                            this.totalConsulta = totalConsulta.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })

                            this.$forceUpdate();
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });

            } else {
                this.text = "Datas informadas não são válidas"
                this.color = "warning"
                this.snackbar = true
            }
        },

        limparPesquisaConsulta() {
            this.$http.get("consultas?idEmpresa=" +
                localStorage.getItem("idEmpresa") +
                "&token=" + localStorage.getItem("token"))
                .then((res) => {
                    if (res.data.status != undefined && res.data.status == 200) {
                        this.consultas = res.data.dados
                        var totalConsulta = 0;
                        for (var i = 0; i < this.consultas.length; i++) {
                            totalConsulta += parseFloat(this.consultas[i].valor)
                            this.consultas[i].dataModificada = this.consultas[i].data.split("T")
                            this.consultas[i].dataModificada = this.formatDate(this.consultas[i].dataModificada[0])

                            this.consultas[i].valorModificado = parseFloat(this.consultas[i].valor).toLocaleString("pt-BR", { style: "currency", currency: "BRL" });
                            this.consultas[i].confirmacaoModificado = "Não informado";
                            if (this.consultas[i].confirmacao == 2) {
                                this.consultas[i].confirmacaoModificado = "Confirmado";
                            } else if (this.consultas[i].confirmacao == 3) {
                                this.consultas[i].confirmacaoModificado = "Não Confirmado";
                            }
                        }

                        this.totalConsulta = totalConsulta.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
                        this.dataDeConsulta = ""
                        this.dataAteConsulta = ""
                        this.$forceUpdate();
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        pesquisarReceber() {
            if (this.dataDeReceber == "" || this.dataAteReceber == "") {
                this.text = "Favor preencher os campos"
                this.color = "warning"
                this.snackbar = true
                return
            }

            var [day, month, year] = this.dataDeReceber.split('/')
            day = (parseInt(day)).toString().padStart(2, '0')

            var dataDe = new Date(year + "-" + month + "-" + day)

            var [day, month, year] = this.dataAteReceber.split('/')
            day = (parseInt(day)).toString().padStart(2, '0')
            var dataAte = new Date(year + "-" + month + "-" + day)

            if (!isNaN(dataDe) && !isNaN(dataAte)) {
                this.$http.get("financeiro?idEmpresa=" + localStorage.getItem("idEmpresa") +
                    "&token=" + localStorage.getItem("token") +
                    "&tipo=" + 1 +
                    "&dataDe=" + dataDe +
                    "&dataAte=" + dataAte)
                    .then((res) => {
                        if (res.data.status != undefined && res.data.status == 200) {
                            this.financeiroReceber = res.data.dados
                            var totalReceber = 0;
                            for (var i = 0; i < this.financeiroReceber.length; i++) {
                                totalReceber += parseFloat(this.financeiroReceber[i].valor)
                                this.financeiroReceber[i].valor = parseFloat(this.financeiroReceber[i].valor).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
                                this.financeiroReceber[i].data = this.dataAtualFormatada(new Date(this.financeiroReceber[i].data))
                            }

                            this.totalReceber = totalReceber.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })

                            this.$forceUpdate();
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });

            } else {
                this.text = "Datas informadas não são válidas"
                this.color = "warning"
                this.snackbar = true
            }
        },

        limparPesquisaReceber() {
            this.$http.get("financeiro?idEmpresa=" + localStorage.getItem("idEmpresa") +
                "&token=" + localStorage.getItem("token") +
                "&tipo=" + 1)
                .then((res) => {
                    if (res.data.status != undefined && res.data.status == 200) {
                        this.financeiroReceber = res.data.dados
                        var totalReceber = 0;
                        for (var i = 0; i < this.financeiroReceber.length; i++) {
                            totalReceber += parseFloat(this.financeiroReceber[i].valor)
                            this.financeiroReceber[i].valor = parseFloat(this.financeiroReceber[i].valor).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
                            this.financeiroReceber[i].data = this.dataAtualFormatada(new Date(this.financeiroReceber[i].data))
                        }

                        this.totalReceber = totalReceber.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })

                        this.dataDeReceber = ""
                        this.dataAteReceber = ""

                        this.$forceUpdate();
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        pesquisarRecebido() {
            if (this.dataDeRecebido == "" || this.dataAteRecebido == "") {
                this.text = "Favor preencher os campos"
                this.color = "warning"
                this.snackbar = true
                return
            }

            var [day, month, year] = this.dataDeRecebido.split('/')
            day = (parseInt(day)).toString().padStart(2, '0')

            var dataDe = new Date(year + "-" + month + "-" + day)

            var [day, month, year] = this.dataAteRecebido.split('/')
            day = (parseInt(day)).toString().padStart(2, '0')
            var dataAte = new Date(year + "-" + month + "-" + day)

            if (!isNaN(dataDe) && !isNaN(dataAte)) {
                this.$http.get("financeiro?idEmpresa=" + localStorage.getItem("idEmpresa") +
                    "&token=" + localStorage.getItem("token") +
                    "&tipo=" + 2 +
                    "&dataDe=" + dataDe +
                    "&dataAte=" + dataAte)
                    .then((res) => {
                        if (res.data.status != undefined && res.data.status == 200) {
                            this.financeiroRecebido = res.data.dados
                            var totalRecebido = 0;
                            for (var i = 0; i < this.financeiroRecebido.length; i++) {
                                totalRecebido += parseFloat(this.financeiroRecebido[i].valor)
                                this.financeiroRecebido[i].valor = parseFloat(this.financeiroRecebido[i].valor).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
                                this.financeiroRecebido[i].data = this.dataAtualFormatada(new Date(this.financeiroRecebido[i].data))
                            }

                            this.totalRecebido = totalRecebido.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })

                            this.$forceUpdate();
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });

            } else {
                this.text = "Datas informadas não são válidas"
                this.color = "warning"
                this.snackbar = true
            }
        },

        limparPesquisaRecebido() {
            this.$http.get("financeiro?idEmpresa=" + localStorage.getItem("idEmpresa") +
                "&token=" + localStorage.getItem("token") +
                "&tipo=" + 2)
                .then((res) => {
                    if (res.data.status != undefined && res.data.status == 200) {
                        this.financeiroRecebido = res.data.dados
                        var totalRecebido = 0;
                        for (var i = 0; i < this.financeiroRecebido.length; i++) {
                            totalRecebido += parseFloat(this.financeiroRecebido[i].valor)
                            this.financeiroRecebido[i].valor = parseFloat(this.financeiroRecebido[i].valor).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
                            this.financeiroRecebido[i].data = this.dataAtualFormatada(new Date(this.financeiroRecebido[i].data))
                        }

                        this.totalRecebido = totalRecebido.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })

                        this.dataDeRecebido = ""
                        this.dataAteRecebido = ""

                        this.$forceUpdate();
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        deleteItem(item) {
            this.editedIndex = this.financeiroReceber.indexOf(item)
            this.financeiroM = Object.assign({}, item)
            this.dialogDelete = true

        },

        deleteItemConfirm() {
            this.closeDelete()

            this.$http.post("deletarFinanceiro", {
                id: this.financeiroM.id, token: localStorage.getItem("token")
            })
                .then((res) => {
                    if (res.data.status != undefined && res.data.status == 200) {
                        this.text = "Conta deletada"
                        this.color = "success"
                        this.snackbar = true
                        this.financeiroM = { id: null, descricao: "", valor: "", data: "", idEmpresa: localStorage.getItem("idEmpresa"), token: localStorage.getItem("token") };

                        this.pegarDados();

                    } else if (res.data.status != undefined && res.data.status == 500) {
                        this.text = res.data.msg
                        this.color = "error"
                        this.snackbar = true
                    }
                })
                .catch((error) => {
                    console.log(error);
                });

        },

        deleteItemRecebido(item) {
            this.editedIndex = this.financeiroRecebido.indexOf(item)
            this.financeiroMRecebido = Object.assign({}, item)
            this.dialogDeleteRecebido = true

        },

        deleteItemConfirmRecebido() {
            console.log(this.financeiroMRecebido)
            this.$http.post("deletarFinanceiro", {
                id: this.financeiroMRecebido.id, token: localStorage.getItem("token")
            })
                .then((res) => {
                    if (res.data.status != undefined && res.data.status == 200) {
                        this.text = "Conta deletada"
                        this.color = "success"
                        this.snackbar = true
                        this.financeiroMRecebido = { id: null, descricao: "", valor: "", data: "", idEmpresa: localStorage.getItem("idEmpresa"), token: localStorage.getItem("token") };
                        this.dialogDeleteRecebido = false

                        this.pegarDados();

                    } else if (res.data.status != undefined && res.data.status == 500) {
                        this.text = res.data.msg
                        this.color = "error"
                        this.snackbar = true
                    }
                })
                .catch((error) => {
                    console.log(error);
                });

        },

        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.financeiroM = Object.assign({}, this.defaultFinanceiro)
                this.editedIndex = -1
            })
        },

        closeDelete() {

            this.dialogDelete = false
            this.$nextTick(() => {
                this.financeiroM = Object.assign({}, this.defaultFinanceiro)
                this.editedIndex = -1


            })
        },

        formatDate(date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${day}/${month}/${year}`
        },

        dataAtualFormatada: function (datan) {
            let data = datan,
                dia = data.getDate().toString().padStart(2, '0'),
                mes = (data.getMonth() + 1).toString().padStart(2, '0'),
                ano = data.getFullYear();
            return `${dia}/${mes}/${ano}`;
        }

    },

    computed: {
        computedDateFormatted() {
            return this.formatDate(this.date)
        },

        computedDateFormattedRecebido() {
            return this.formatDate(this.dateRecebido)
        },
    },

    created() {

    },

    beforeMount() {
        var logado = localStorage.getItem("login")
        if (logado != undefined && logado == "true") {
            this.pegarDados()
        } else {
            this.$router.push('login')
        }
    },

    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
    },
}
</script>
